/* adding fonts */
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/* default css html stylings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  z-index: 1;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

.nicktill {
  line-height: normal;
  font-weight: 600;
  font-style: italic;
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
  /* bold */
  font-weight: 700;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  /* background-color: #121212; */
}

nav.navbar .navbar-nav {
  margin-right: 450px;
  /* margin-right: auto; */
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: flex;
  color: #fff;
}
.social-icon a {
  width: 250px;
  height: 250px;
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  line-height: 1;
  transition: transform 0.5s;
  transform: scale(1);
}

.social-icon a:hover {
  transform: scale(1.4);
}

.social-icon a:not(:hover) {
  transform: scale(1);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50px;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  transform: scale(1.06);
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/* for information component */
.info-container {
  display: block;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20% 0 0 20%;
  width: 30%;
}

.arrow-container .arrow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 3rem;
  margin-bottom: 2rem;
}

.arrow-blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.social-icon a p {
  padding: 5px;
}

.welcome {
  font-size: 24px;
  background-color: rgb(150, 64, 118);
  width: 290px;
}

.linkedin:hover .github:hover .resume:hover {
  transform: scale(1.2);
}

/* For screens smaller than 768px */
@media (max-width: 767px) {
  /* Adjust the padding of the navbar and set the width to 100% */
  .headerImg,
  .headerImgBackground {
    display: none;
  }
  span.navbar-text {
    align-items: center;
    justify-content: space-between;
  }
  .social-icon {
    align-items: center;
  }
  .arrow-container {
    transform: scale(50%);
  }
  .banner {
    text-align: center;
  }
  nav.navbar {
    padding: 20px 0;
    margin-bottom: 50px;
    text-align: center;
    display: flex;
  }
  /* Center the navbar links and reduce the font size */
  nav.navbar .navbar-nav {
    margin-right: 0;
    text-align: center;
  }
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-size: 16px;
    margin-right: 35px;
    display: none;
  }
  /* Adjust the size and spacing of the social icons */
  .social-icon a {
    width: 50px;
    height: 50px;
    margin-left: 12px;
  }
  /* Adjust the margin of the projects container */
  .projects-container {
    height: 60px;
  }
  /* for information component */
  .info-container {
    margin: 50% 0 0 10%; /* 20% from the top, 20% from the left */
    font-size: 12px;
    align-items: center;
    padding: 20px;
    width: 80%;
  }
  .arrow {
    margin-bottom: 15px;
  }

  .header-background {
    display: none;
  }
  .no-show-mobile {
    display: none;
  }
}

/* For screens between 768px and 992px */
@media (min-width: 768px) and (max-width: 991px) {
  /* Reduce the font size of the navbar links */
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-size: 16px;
  }
}

/* For screens larger than 992px */
@media (min-width: 992px) {
  /* Reset the navbar padding and margins */
  nav.navbar {
    padding: 18px 0;
    margin-bottom: 25px;
  }
  nav.navbar .navbar-nav {
    margin-right: 450px;
  }
  /* Reset the size and spacing of the social icons */
  .social-icon a {
    width: 75px;
    height: 75px;
    margin-right: 6px;
  }
}

.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url("./assets/img/banner-bg.png");
  background-color: black;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  /* no repeat */
  background-repeat: no-repeat;
}

.banner:hover {
  filter: blur(0); /* decrease the blur effect on hover */
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.headerImg {
  animation: updown 3s linear infinite;
  z-index: 0;
}
.headerImgBackground {
  animation: updown 3s linear infinite;
  position: absolute;
  right: 0%;
  top: 0%;
  z-index: -1;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.gradient {
  background: linear-gradient(
    to right,
    #845ec2,
    #d65db1,
    #ff6f91,
    #845ec2,
    #2c73d2,
    #0081cf,
    #0089ba,
    #008e9b,
    #008f7a
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 15s linear infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.hideProjectsBtn {
  background-color: #f2f2f2;
  color: #2b2b2b;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 700;
  border: none;
}
